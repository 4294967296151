<template>
  <tr>
    <td>
      {{alert.dateToLocal()}}
    </td>
    <td>
      {{item.lvl}}
    </td>
    <td>
      {{item.lvl_id}}
    </td>
    <td>
      {{item.delivery_day_2}}
    </td>
    <td>
      {{item.delivery_day_1}}
    </td>
  </tr>
</template>

<script>
import UltraDetailedMixin from './mixin/UltraDetailedMixin'

export default {
  mixins: [UltraDetailedMixin],
  name: 'PerfFirstDeliveryDropDetailed',
  props: [],
  components: {

  },
  data: function () {
    return {

    }
  },
  created: function () {
  },
  mounted: function () {
  },
  methods: {

  },
  computed: {

  },
  watch: {
  }
}
</script>

<style scoped>

</style>
